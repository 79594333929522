(function () {
    'use strict';
    // DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
    angular
        .module('lmsApp')
        .constant('VERSION', "1.4.1")
        .constant('GIT', {
	"message": "Merge branch 'dev' into 'master'\n\nfix: Параметры Диалоги и Расслыки исправлены.\n\nSee merge request lms-mob-edu/lms-legacy/legacy-front!142",
	"commit": "99ef9d4339cc78560fae305cc1b6d9297c5a0c00",
	"branch": "master",
	"date": "2023-07-04T09:14:01.000Z"
})
        .constant('DEBUG_INFO_ENABLED', false)
        .constant('ENV', "prod")
        .constant('API', {
	"newAdmin": "/adm",
	"course": "/course"
})
        .constant('innopolis', undefined)
        .constant('innopolis_stage', undefined)
        .constant('edu4', undefined)
;
})();
